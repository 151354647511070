.whyPayezyContainer {
  display: flex;
  margin-left: 162px;
  align-items: center;
  @media screen and (max-width: 992px) {
    flex-direction: column;
    margin-left: 0;
  }
}
.whyPayezyMob {
  color: var(--white, var(--linear-stroke-of-blug-bg, #fff));
  text-align: center;
  font-family: Michroma;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 3px;
  border-bottom: 1px solid #0595b6;
}
.whyPayezy {
  color: #e6e6e6;
  font-family: Michroma;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 72px */
  width: 510px;

  @media screen and (min-width: 1300px) and (max-width: 1465px) {
    width: 434px;
    font-size: 36px;
  }
  @media screen and (min-width: 1250px) and (max-width: 1341px) {
    width: 350px;
    font-size: 34px;
  }
  @media screen and (min-width: 1150px) and (max-width: 1296px) {
    width: 280px;
    font-size: 26px;
  }
  @media screen and (min-width: 992px) and (max-width: 1151px) {
    width: 260px;
    font-size: 21px;
  }
  @media screen and (max-width: 992px) {
    width: auto;
    color: #e6e6e6;

    font-family: Michroma;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 35.2px */
    text-align: center;
  }
}
.comparisonMob {
  font-family: Metrophobic;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: 160%; /* 33.6px */
  background: var(
    --blue-linear-membervouch,
    linear-gradient(
      106deg,
      #05d2ff 38.97%,
      rgba(37, 63, 224, 0.62) 107.51%,
      rgba(6, 14, 208, 0.28) 151.44%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 25px;
}
.features {
  font-family: Metrophobic;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;

  line-height: 160%; /* 33.6px */
  background: var(
    --blue-linear-membervouch,
    linear-gradient(
      106deg,
      #05d2ff 38.97%,
      rgba(37, 63, 224, 0.62) 107.51%,
      rgba(6, 14, 208, 0.28) 151.44%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 123px;
  margin-bottom: -14px;
  border-bottom: 1px solid #0595b6;
  @media screen and (max-width: 992px) {
    display: none;
  }
}
.lineContainer {
  width: 62%;
  border-bottom: 1px solid #0595b6;
  margin-bottom: -48px;
  @media screen and (max-width: 992px) {
    display: none;
  }
}
.featuresContainer {
  margin-left: 162px;
  margin-right: 122px;
  margin-top: 22px;
  margin-bottom: 313px;
  border-radius: 10px;
  padding: 7px 30px 18px 30px;
  background: linear-gradient(180deg, #232532 3.57%, #131521 103.57%);
  @media screen and (max-width: 1275px) and (min-width: 1196px) {
    padding: 22px 43px 33px 43px;
  }
  @media screen and (max-width: 1195px) and (min-width: 990px) {
    padding: 22px 23px 33px 23px;
  }
}
.payezyImages {
  width: 120px;
}
.westernUnionImage {
  width: 87px;
  height: 20px;
}
.wiseImage {
  width: 83px;
  height: 20px;
}
.remitlyImage {
  width: 96px;
  height: 26px;
}
.ofxIcon {
  height: 28px;
}
.instaRoom {
  width: 108.3px;
  height: 19.001px;
}
.toolTipIcon {
  cursor: pointer;
  margin-left: 6px;
  margin-bottom: -1px;
}
.toolTipStyle {
  border-radius: 8px;
  background: #384455;
  width: 188px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
}
.toolTipStyleTrueValue {
  border-radius: 8px;
  background: #384455;
  width: 190px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #ffffff;
  text-align: justify;
  padding: 10px 10px 10px 10px;
  font-family: "metrophobic";
}
.liveGoogleRate {
  margin-top: 30px;
  color: var(--white, var(--linear-stroke-of-blug-bg, #fff));
  text-align: left;
  font-family: Metrophobic;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-left: 162px;
}
.liveGoogleRateMob {
  color: var(--linear-stroke-of-blug-bg, #fff);
  text-align: center;
  font-family: Metrophobic;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 121.5%; /* 15.795px */
  margin-bottom: 16px;
}
.priceComaprisonContainer {
  background: linear-gradient(180deg, #232532 3.57%, #131521 103.57%);
  padding: 16px 19px 20px 19px;
  border-radius: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 170px;
}

.exchangeRateInMob,
.recipientGetsInMob,
.exchangeRateNotPayezyInMob {
  color: var(--Light-Blue-New-Payezy, #a1e6f6);
  font-family: "Metrophobic";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 121.5%; /* 18.225px */
}
.exchangeRateNotPayezyInMob {
  color: #fff;
}
.recipientGetsInMob {
  margin-top: 25px;
}
.USDToINRInamob {
  color: #b4b4b4;
  font-family: Metrophobic;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 121.5%; /* 13.365px */
  margin-bottom: 14px;
  margin-top: 2px;
}
.priceComparisonSubContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
  border-bottom: 1px solid #524f67;
  padding-bottom: 18px;
}
.priceComparisonSubContainerInstarem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
}
.transferRateInMob {
  color: var(--Light-Blue-New-Payezy, #a1e6f6);
  font-family: Metrophobic;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 121.5%; /* 18.225px */
  margin-bottom: 28px;
}
.payezyImageMob {
  width: 105px;
  height: 20px;
}
.payezyImageMobContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 28px;
}
.wiseLyImageMob {
  width: 80.868px;
  height: 18.308px;
}
.remitlyIconMob {
  width: 80.868px;
  height: 20px;
}
.ofxIconMob {
  width: 80.868px;
  height: 22px;
}
.westernUnionImageMob {
  width: 83px;
  height: 19px;
}
.instaremImageMob {
  width: 100.868px;
  height: 18.308px;
}
.trueRateInMob {
  color: var(--Light-Blue-New-Payezy, #a1e6f6);
  font-family: Metrophobic;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 121.5%; /* 18.225px */
  margin-top: 25px;
}
.priceDifferenceInMob,
.priceDifference {
  color: #fd5252;
}
.priceDifference {
  font-size: 13px;
}
.priceDifferenceInMob {
  font-size: 14px;
}
.downArrow {
  width: 10px;
  height: 10px;
}
.totalCostInMob {
  color: var(--Light-Blue-New-Payezy, #a1e6f6);
  font-family: "Metrophobic";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 121.5%;
  margin-bottom: 28px;
  margin-top: 25px;
}
.comparisonTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  thead {
    vertical-align: text-top;
  }
  th,
  td {
    padding-left: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    vertical-align: baseline;
  }

  th {
    color: var(--Light-Blue-New-Payezy, #a1e6f6);
    font-family: "Metrophobic";
    font-size: 21px;
    font-weight: 400;
    line-height: 121.5%;

    .subHeading {
      color: #b4b4b4;
      font-family: "Metrophobic";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .payezyRow {
    border: 2px solid #a1e6f6;

    td {
      color: var(--Light-Blue-New-Payezy, #a1e6f6);
      font-size: 18px;
      border: 2px solid #a1e6f6;

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-right: none;
      }

      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-left: none;
      }

      &:not(:first-child):not(:last-child) {
        border-left: none;
        border-right: none;
      }
    }
  }

  .providerRow {
    td {
      font-size: 16px;
    }
    .westernUnionProviderCell,
    .providerCell {
      color: #fff;
    }
    .westernUnionPriceDifferenceCell,
    .westernUnionProviderCell {
      padding-top: 22px;
    }
    .westernUnionPriceDifferenceCell,
    .priceDifferenceCell {
      padding-left: 0;
      color: #fd5252;
    }
  }
}
