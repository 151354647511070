.simpleWayToSendMoneyMainContainer {
  color: white;
}
.simpleWayToSendMoney {
  color: var(--white, #fff);
  font-family: Michroma;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 557px;
  margin-bottom: 38px;
  @media screen and (max-width: 992px) {
    width: auto;
    color: var(--white, #fff);
    text-align: center;
    font-family: Michroma;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 28px;
    margin-left: 20px;
    margin-right: 20px;
  }
}
.simpleWayToSendMoneyHeadings {
  color: var(--white, #fff);
  font-family: Exo;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0px;
  margin-bottom: 0px;
  @media screen and (max-width: 992px) {
    color: var(--white, #fff);
    font-family: Exo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.simpleWayToSendMoneyDef {
  color: var(--Gray-font, #a5a2b8);
  font-family: Exo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0px;
  margin-bottom: 0px;
  @media screen and (max-width: 992px) {
    font-size: 12px;
    margin-top: 7px;
  }
}
.simpleWayToSendMoneySubContainer {
  display: flex;

  gap: 17px;
  @media screen and (max-width: 992px) {
    padding: 11px 5px 19px 6px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
    background: linear-gradient(180deg, #22242f 3.57%, #141622 103.57%);
  }
}
.simpleWayToSendMoneyDefContainer {
  border-radius: 10px;
  padding: 15px 11px 39px 11px;
  margin-top: 0;
  width: 553px;
  @media screen and (max-width: 992px) {
    padding: 0;
    width: auto;
  }
}
.mainContainer {
  position: relative;
  z-index: 2;
  overflow: hidden;
  margin-left: 162px;
  display: flex;
  justify-content: space-between;
  margin-bottom: -430px;
  height: 1350px;
  @media screen and (max-width: 992px) {
    flex-direction: column;
    margin-bottom: 0px;
    margin-left: 0;
    position: relative;
    z-index: 9;
    background: #0a0c19;
    height: auto;
  }
}
.imageContainer {
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.imageContainer {
  width: 37%;
  @media screen and (min-width: 1450px) and (max-width: 1500px) {
    width: 38%;
  }
  @media screen and (min-width: 1400px) and (max-width: 1450px) {
    width: 40%;
  }
  @media screen and (min-width: 1350px) and (max-width: 1400px) {
    width: 41%;
  }
  @media screen and (min-width: 1300px) and (max-width: 1350px) {
    width: 42%;
  }
  @media screen and (min-width: 992px) and (max-width: 1300px) {
    width: 43%;
  }
}
.simpleWayToSendMoneyMainContainer {
  cursor: pointer;
  height: 600px;
  position: relative; /* Ensure the container is positioned relatively */
  @media screen and (max-width: 992px) {
    cursor: none;
    height: auto;
  }
}

.simpleWayToSendMoneyDefContainer {
  transition: background 0.3s ease;
}

.simpleWayToSendMoneyDefContainer.active {
  background: linear-gradient(180deg, #22242f 3.57%, #141622 103.57%);

  @media screen and (max-width: 992px) {
    background: none;
    color: var(--white, #fff);
    margin-top: 0;
    font-family: Exo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.simpleWayToSendMoneyDefContainer::before {
  content: "";

  top: 0;
  left: 0;
  margin-top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition: background-color 0.3s ease; /* Add transition for smooth background color change */
}

.activePara {
  color: #a1e6f6;
  @media screen and (max-width: 992px) {
    color: var(--white, #fff);
    font-family: Exo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.simpleWayToSendMoneyIconOverlay {
  position: relative;
  left: 94px;
  bottom: 739px;
  border-radius: 10px;
  z-index: 2;
  animation: fadeIn 0.8s ease-out forwards; /* Apply fadeIn animation with 1 second duration */
}
@keyframes fadeIn {
  from {
    opacity: 0; /* Start from fully transparent */
  }
  to {
    opacity: 1; /* End at fully opaque */
  }
}
.oneImage,
.twoImage,
.threeImage,
.fourImage {
  margin-top: 18px;
  @media screen and (max-width: 992px) {
    width: 20.474px;
    height: 20.474px;
    margin-top: 3px;
  }
}
.simpleWayToSendMoneyIconOverlayMobile {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-top: 28px;
  margin-bottom: 48px;
}
.simpleWayToSendMoneyIconOverlayMobileOne {
  display: flex;
  margin-top: 28px;
  margin-bottom: 48px;
}
.simpleWayToSendMoneyIconOverlayMobileOneContainer {
  display: flex;
  justify-content: end;
}

.simpleWayToSendMoneySubContainer {
  // Your existing styles for the container

  // Define initial styles for the background animation
  &.animateUp {
    animation: animateUpBackground 0.5s ease forwards;
  }

  &.animateDown {
    animation: animateDownBackground 0.5s ease forwards;
  }

  // Define keyframes for scrolling up animation
  @keyframes animateUpBackground {
    0% {
      // Initial background styles when scrolling up
      background-color: transparent; // Change as per your design
    }
    100% {
      // Final background styles when scrolling up
      background-color: #a1e6f6; // Change as per your design
    }
  }

  // Define keyframes for scrolling down animation
  @keyframes animateDownBackground {
    0% {
      // Initial background styles when scrolling down
      background-color: #a1e6f6; // Change as per your design
    }
    100% {
      // Final background styles when scrolling down
      background-color: transparent; // Change as per your design
    }
  }
}
