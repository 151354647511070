.withZeroFee {
  color: #e6e6e6;
  font-family: "Metrophobic";
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-align: center;
  /* 28.8px */
  margin-bottom: 25px;
  @media screen and (min-width: 1275px) and (max-width: 1449px) {
    font-size: 21px;
  }
  @media screen and (min-width: 990px) and (max-width: 1274px) {
    font-size: 18px;
  }
}
.withZeroFeeMob {
  font-size: 17px;
  margin-bottom: 37px;
  text-align: center;
}
.zeroText {
  color: #83baca;
  font-size: 50px;
  margin-right: 3px;
  @media screen and (max-width: 992px) {
    font-size: 40px;
  }
}
.joinWaitlistContainer {
  display: flex;
  flex-direction: column;
  padding-top: 318px;
  padding-bottom: 356px;
  justify-content: center;
  align-items: center;
  background: url("../../assets/joinWaitlistBackground.svg");
  background-repeat: no-repeat;
  background-position-y: 60px;
  background-position-x: center;
  @media screen and (min-width: 1275px) and (max-width: 1449px) {
    background-position-y: 50px;
  }
  @media screen and (min-width: 990px) and (max-width: 1274px) {
    background-position-y: 40px;
  }
  @media screen and (max-width: 992px) {
    margin-top: 0px;
    flex-direction: column;
    padding-right: 0px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 0px;

    background: url("../../assets/joinwaitlistBackgroundMob.svg");
    background-repeat: no-repeat;
    background-position-y: 55px;
    background-position-x: center;
  }
}
.joinWaitlistButton {
  cursor: pointer;
  padding-left: 18px;
  padding-right: 9px;
  color: white;
  border: 1px solid #fff;
  background: none;
  border-radius: 32px;
  margin-bottom: 40px;
  width: 209px;
  height: 55px;
  font-family: Michroma;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: color 0.3s ease, background-clip 0.3s ease, border-color 0.3s ease; /* Add transition for smooth color, background, and border changes */
  @media screen and (max-width: 992px) {
    font-size: 18px;
    background: var(
      --blue-linear-membervouch,
      linear-gradient(
        106deg,
        #05d2ff 38.97%,
        rgba(37, 63, 224, 0.62) 107.51%,
        rgba(6, 14, 208, 0.28) 151.44%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 1px solid #05d2ff; /* Start with a transparent border */
    margin-bottom: 0;
  }
  @media screen and (min-width: 992px) and (max-width: 1159px) {
    font-size: 19px;
    padding: 11px 16px;
  }
}

.joinWaitlistButton:hover {
  background: var(
    --blue-linear-membervouch,
    linear-gradient(
      106deg,
      #05d2ff 38.97%,
      rgba(37, 63, 224, 0.62) 107.51%,
      rgba(6, 14, 208, 0.28) 151.44%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 1px solid #05d2ff; /* Start with a transparent border */
  box-shadow: 0 0 10px #05d2ff; /* Add box shadow on hover */
}
.buttonContainer {
  z-index: 2;
}

.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200000;
  color: white;
}
@keyframes zoomInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
}
.popupContent {
  background: #081527;
  animation: zoomInAnimation 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  border-radius: 15px;
  width: 548px;
  padding: 66px 71px 44px 71px;
  height: auto;
  overflow: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
    padding: 46px 35px 30px 35px;
  }
}
.joinWaitlistButtonArrow {
  margin-left: -42px;
}
.joinWaitlistButtonArrowContainer {
  cursor: pointer;
  margin-left: 19px;
  padding: 15px;
  border-radius: 50px;
  background: var(
    --blue-linear-membervouch,
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    linear-gradient(
      106deg,
      #05d2ff 38.97%,
      rgba(37, 63, 224, 0.62) 107.51%,
      rgba(6, 14, 208, 0.28) 151.44%
    )
  );
}
.joinWaitlistButton {
  /* Your existing styles */
  position: relative; /* Ensure positioning for the button content */
}

.joinWaitlistButtonArrow {
  /* Your existing styles for the arrow icon */
  position: absolute; /* Position it relative to the button */
  top: 50%; /* Position it in the middle vertically */
  left: calc(100% + 1px); /* Position it to the right of the button */
  transform: translateY(-50%); /* Center it vertically */
  transition: transform 0.4s ease-in-out; /* Smooth transition for rotation */
  transition-delay: 0.2s; /* Add a delay of 0.2 seconds */
}

.joinWaitlistButton:hover .joinWaitlistButtonArrow {
  /* Rotate the arrow icon to 45 degrees */
  transform: translateY(-50%) rotate(45deg);
}
