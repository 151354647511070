.supportEmailStatement {
  color: var(--white, #fff);
  font-family: Metrophobic;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 20.4px */
  margin-top: 16px;
  margin-bottom: 0;
  @media screen and (max-width: 992px) {
    text-align: center;
    color: var(--white, #fff);
    text-align: center;
    font-family: Metrophobic;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 31px;
  }
}
.supportEmail {
  color: var(--Light-Blue-New-Payezy, #a1e6f6);
  font-family: Metrophobic;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-left: 3px;
}
.footerSubContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 91px;
  border-bottom: 1px solid #434343;
  @media screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 39px;
  }
}
.privacyPolicy,
.termsOfUse {
  margin-top: 15px;
  text-decoration: none;
  color: #fff;
  font-family: Metrophobic;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 15px */
  @media screen and (max-width: 992px) {
    font-size: 11px;
  }
}
.privacyPolicy:hover,
.termsOfUse:hover {
  color: #a1e6f6;
}

.privacyPolicyContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 992px) {
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
    gap: 10px;
  }
}
.copyRight {
  color: var(--Gray-font, #a5a2b8);
  font-family: Metrophobic;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 15px */
  margin-top: 15px;
}
.footerMainContainer {
  position: relative;
  z-index: 3;
  background: linear-gradient(180deg, #22242f 3.57%, #141622 103.57%);
  padding-top: 47px;
  padding-left: 29px;
  padding-bottom: 39px;
  padding-right: 29px;
  margin-bottom: 59px;
  margin-left: 60px;
  margin-right: 60px;
  border-radius: 10px;
  margin-bottom: 130px;
  bottom: 30px;
  @media screen and (max-width: 992px) {
    margin-right: 0px;
    padding-top: 26px;
    padding-left: 0px;
    padding-bottom: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
    margin-bottom: 0px;
  }
}
.termsOfUse,
.privacyPolicyIcon {
  margin-left: 19px;
  @media screen and (max-width: 992px) {
    margin-left: 0;
  }
}
.linkedinIcon,
.discordIcon,
.XIcon {
  margin-right: 18px;
  cursor: pointer;
  border: 1px solid transparent;
  @media screen and (max-width: 992px) {
    width: 48px;
    height: 48px;
  }
}
.instagramIcon,
.linkedinIcon {
  @media screen and (max-width: 992px) {
    width: 48px;
    height: 48px;
  }
}
.termsOfUse:hover,
.privacyPolicy:hover {
  cursor: pointer;
}
.instagramIcon,
.linkedinIcon {
  border: 1px solid transparent;
}
.instagramIcon:hover,
.XIcon:hover,
.discordIcon:hover,
.linkedinIcon:hover {
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #a1e6f6;
  box-shadow: 0 0 10px #a1e6f6;
}
.logoContainer {
  @media screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.copyRightMobile {
  color: var(--Gray-font, #a5a2b8);
  font-family: Metrophobic;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 9px */
  text-align: center;
  padding-bottom: 20px;
  margin-top: 10px;
}
