.whyPayezyContainer {
  display: flex;
  margin-left: 162px;
  align-items: center;
  @media screen and (max-width: 992px) {
    flex-direction: column;
    margin-left: 0;
  }
}
.whyPayezy {
  color: #e6e6e6;
  font-family: Michroma;
  font-size: 44px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 72px */
  width: 447px;

  @media screen and (min-width: 992px) and (max-width: 1519px) {
    width: 400px;
    font-size: 40px;
  }
  @media screen and (min-width: 1300px) and (max-width: 1465px) {
    width: 370px;
    font-size: 36px;
  }
  @media screen and (min-width: 1250px) and (max-width: 1341px) {
    width: 350px;
    font-size: 34px;
  }
  @media screen and (min-width: 1150px) and (max-width: 1296px) {
    width: 280px;
    font-size: 26px;
  }
  @media screen and (min-width: 992px) and (max-width: 1151px) {
    width: 260px;
    font-size: 21px;
  }
  @media screen and (max-width: 992px) {
    width: auto;
    color: #e6e6e6;

    font-family: Michroma;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 35.2px */
    text-align: center;
  }
}
.features {
  font-family: Metrophobic;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 33.6px */
  background: var(
    --blue-linear-membervouch,
    linear-gradient(
      106deg,
      #05d2ff 38.97%,
      rgba(37, 63, 224, 0.62) 107.51%,
      rgba(6, 14, 208, 0.28) 151.44%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 123px;
  margin-bottom: -15px;
  border-bottom: 1px solid #0595b6;
  @media screen and (max-width: 992px) {
    display: none;
  }
}
.lineContainer {
  width: 62%;
  border-bottom: 1px solid #0595b6;
  margin-bottom: -48px;
  @media screen and (max-width: 992px) {
    display: none;
  }
}
.feauturesContainer {
  margin-left: 162px;
  display: flex;
  justify-content: space-between;
  margin-right: 122px;
  margin-top: 48px;
  margin-bottom: 313px;
  @media screen and (max-width: 992px) {
    flex-direction: column;
    margin-right: 20px;
    align-items: center;
    margin-left: 20px;
    margin-top: 28px;
    margin-bottom: 181px;
    gap: 40px;
  }
}
.featuresText {
  display: flex;
  justify-content: space-between;
  padding: 0px 30px 0px 30px;
  color: #e6e6e6;
  text-align: center;
  font-family: Michroma;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  padding-top: 10px;
  margin: 0;
  line-height: 121.5%; /* 25.515px */
  @media screen and (max-width: 992px) {
    padding-top: 10px;
    font-size: 15px;
  }
  @media screen and (min-width: 994px) and (max-width: 1420px) {
    font-size: 17px;
  }
}
.fullyTransparentIcon {
  margin-left: 49px;
  @media screen and (max-width: 992px) {
    width: 21.627px;
    height: 24.248px;
    margin-left: 52px;
  }
}
.fastAndEasyIcon {
  margin-left: 93px;
  @media screen and (max-width: 992px) {
    width: 19.686px;
    height: 22.836px;
    margin-left: 87px;
  }
}
.bestFXRateIcon {
  margin-left: 30px;
  @media screen and (max-width: 992px) {
    width: 14.973px;
    height: 23.641px;
    margin-left: 35px;
  }
}
.definition {
  padding: 16px 30px 21px 29px;
  color: var(--Gray-font, #a5a2b8);
  font-family: Metrophobic;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 121.5%; /* 18.225px */
  text-align: justify;
  letter-spacing: -1px;
  margin: 0;
  @media screen and (max-width: 992px) {
    width: 253px;
    font-size: 13px;
    padding: 25px 24px 26px 13px;
  }
}
.feauturesSubContainer {
  width: 368px;
  height: 183px;
  background: linear-gradient(180deg, #232532 3.57%, #131521 103.57%);
  border-radius: 5px;
}
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(50px); /* Initially translate each div 50px down */
  }
  100% {
    opacity: 1;
    transform: translateY(
      0
    ); /* Translate each div back to its original position */
  }
}

@keyframes slideInMob {
  from {
    transform: translateX(
      -100%
    ); /* Start position outside the viewport on the left */
    opacity: 0; /* Start with opacity 0 */
  }
  to {
    transform: translateX(0); /* End position at the original position */
    opacity: 1; /* End with opacity 1 */
  }
}

.feauturesSubContainer:nth-child(1) {
  animation-delay: 0.5s; /* Delay the animation for the first div by 0.1s */
}

.feauturesSubContainer:nth-child(2) {
  animation-delay: 0.7s; /* Delay the animation for the second div by 0.2s */
}

.feauturesSubContainer:nth-child(3) {
  animation-delay: 0.9s; /* Delay the animation for the third div by 0.3s */
}

/* Styles for viewport width up to 992px */
@media screen and (max-width: 992px) {
  .feauturesSubContainer {
    width: 100%;
    height: 189px;
  }
  .featuresText {
    font-size: 16px;
    padding: 16px 24px 0px 13px;
  }
}

/* Styles for viewport width from 992px to 1200px */
@media screen and (min-width: 1151px) and (max-width: 1200px) {
  .feauturesSubContainer {
    width: 281px;
    height: 172px;
  }
  .featuresText {
    font-size: 14px;
  }
}
@media screen and (min-width: 1084px) and (max-width: 1150px) {
  .feauturesSubContainer {
    width: 261px;
    height: 176px;
  }
  .featuresText {
    font-size: 14px;
  }
}
@media screen and (min-width: 1022px) and (max-width: 1083px) {
  .feauturesSubContainer {
    width: 241px;
    height: 176px;
  }
  .featuresText {
    font-size: 12px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .feauturesSubContainer {
    width: 320px;
    height: 172px;
  }
  .featuresText {
    font-size: 14px;
  }
}
@media screen and (min-width: 1401px) and (max-width: 1700px) {
  .feauturesSubContainer {
    width: 371px;
    height: 172px;
  }
  .featuresText {
    font-size: 14px;
  }
}
@media screen and (min-width: 993px) and (max-width: 1021px) {
  .feauturesSubContainer {
    width: 231px;
    height: 193px;
  }
  .featuresText {
    font-size: 12px;
  }
}

/* Styles for viewport width from 1201px to 1300px */
@media screen and (min-width: 1201px) and (max-width: 1300px) {
  .feauturesSubContainer {
    width: 300px;
    height: 166px;
  }
  .featuresText {
    font-size: 14px;
  }
}

/* Add more media queries for other screen sizes as needed */
/* Styles for viewport width up to 992px */
@media screen and (max-width: 992px) {
  .feauturesSubContainer {
    width: 290px;
    height: 153px;
  }
}

/* Styles for viewport width from 1380px to 1419px */
@media screen and (min-width: 1380px) and (max-width: 1419px) {
  .feauturesSubContainer {
    width: 350px;
    height: 190px;
  }
}

/* Styles for viewport width from 1500px to 1549px */
@media screen and (min-width: 1500px) and (max-width: 1549px) {
  .feauturesSubContainer {
    width: 380px;
    height: 152px;
  }
}

/* Styles for viewport width from 1550px to 1599px */
@media screen and (min-width: 1550px) and (max-width: 1599px) {
  .feauturesSubContainer {
    width: 400px;
    height: 152px;
  }
}
@media screen and (min-width: 1670px) and (max-width: 1440px) {
  .whyPayezy {
    width: 400px;
    font-size: 43px;
  }
}

/* Styles for viewport width 1600px and wider */
@media screen and (min-width: 1600px) {
  .feauturesSubContainer {
    width: 425px;
    height: 152px;
  }
}
