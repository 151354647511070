.comingSoonMainContainer {
  display: flex;
  align-items: center;
  margin-left: 40px;
  margin-bottom: 330px;
}
.comingSoonMainContainerMob {
  flex-direction: column;
  gap: 30px;
  margin-left: 0;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 50px;
}

.comingSoonTextMob,
.comingSoonText {
  color: #e6e6e6;
  font-family: Michroma;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 52.8px */
  letter-spacing: 0.99px;
}
.comingSoonText {
  font-size: 33px;
  @media screen and (max-width: 1120px) {
    font-size: 28px;
  }
  @media screen and (max-width: 1040px) {
    font-size: 26px;
  }
}
.comingSoonTextMob {
  font-size: 22px;
}
.comingSoonIcon {
  @media screen and (max-width: 1250px) {
    width: 700px;
  }
  @media screen and (max-width: 1170px) {
    width: 650px;
  }
  @media screen and (max-width: 1120px) {
    width: 630px;
  }
  @media screen and (max-width: 1040px) {
    width: 600px;
  }
}
.comingSoonIconMob {
  width: 420px;
  @media screen and (max-width: 425px) {
    width: 380px;
  }
  @media screen and (max-width: 390px) {
    width: 360px;
  }
  @media screen and (max-width: 360px) {
    width: 340px;
  }
  @media screen and (max-width: 340px) {
    width: 310px;
  }
}
.comingSoonIconsContainer {
  display: flex;
  gap: 14px;
  margin-top: 5px;
}
.appStoreIcon,
.playStoreIcon {
  @media screen and (max-width: 1120px) {
    width: 148px;
  }
  @media screen and (max-width: 1040px) {
    width: 138px;
  }
}
.appStoreIconMob,
.playStoreIconMob {
  width: 115px;
}
